import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ProductFilter } from '../../../shared/interfaces/product-filter';
import { DirectionService } from '../../../shared/services/direction.service';
import { ProductService } from '../../../shared/services/product.service';

@Component({
  selector: 'app-widget-filters',
  templateUrl: './widget-filters.component.html',
  styleUrls: ['./widget-filters.component.scss'],
})
export class WidgetFiltersComponent {
  public filters: ProductFilter[] = [];

  isPlatformBrowser = isPlatformBrowser(this.platformId);
  rightToLeft = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private direction: DirectionService,
    private productService: ProductService
  ) {
    this.filters = [null, null];
    this.filters[1] = {
      name: 'Prijs',
      type: 'price',
      options: {
        min: 0,
        max: 100,
        from: 0,
        to: 100,
      },
    };
    if (productService.checkCategoriesLoaded()) {
      this.filters[0] = productService.getCategoriesForFilter();
      this.filters[1].options = {
        min: productService.getLowestProductPrice(),
        max: productService.getHighestProductPrice(),
        from: productService.getLowestProductPrice(),
        to: productService.getHighestProductPrice(),
      };
    }
    productService.categoriesUpdated.subscribe(() => {
      this.filters[0] = productService.getCategoriesForFilter();
      this.filters[1].options = {
        min: productService.getLowestProductPrice(),
        max: productService.getHighestProductPrice(),
        from: productService.getLowestProductPrice(),
        to: productService.getHighestProductPrice(),
      };
    });
    this.rightToLeft = this.direction.isRTL();
  }
}
