import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ProductCategory } from '../../../shared/interfaces/product-category';
import { DirectionService } from '../../../shared/services/direction.service';
import { ProductService } from '../../../shared/services/product.service';

@Component({
  selector: 'app-block-slideshow',
  templateUrl: './block-slideshow.component.html',
  styleUrls: ['./block-slideshow.component.scss'],
})
export class BlockSlideshowComponent {
  @Input() withDepartments = false;

  slide = {
    title: 'Speelgoed en hobby',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
    image_classic: 'assets/images/slides/webshop1.jpg',
    image_full: 'assets/images/slides/webshop1.jpg',
    image_mobile: 'assets/images/slides/webshop1.jpg',
    url: '',
  };

  category: ProductCategory;

  constructor(
    public sanitizer: DomSanitizer,
    private direction: DirectionService,
    private productService: ProductService
  ) {
    if (productService.checkCategoriesLoaded()) {
      this.setCategoryAndSlide();
    }
    this.productService.categoriesUpdated.subscribe(() =>
      this.setCategoryAndSlide()
    );
  }

  private setCategoryAndSlide() {
    this.category = this.productService.getCategories()[
      Math.floor(Math.random() * this.productService.getCategories().length)
    ];

    this.slide.title = this.category.name;
    this.slide.text = this.category.description;
    this.slide.url = this.category.slug;
  }
}
