import { NavigationLink } from '../app/shared/interfaces/navigation-link';

export const navigation: NavigationLink[] = [
  {
    label: 'Account',
    url: '',
    menu: {
      type: 'menu',
      items: [
        { label: 'Login', url: '' },
        { label: 'Bewerk profiel', url: '' },
        { label: 'Bestel historie', url: '' },
        { label: 'Verander wachtwoord', url: '' }
      ]
    }
  },
  {
    label: 'Blog',
    url: './blog'
  }
];
