import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { mobileMenu } from '../../../../../data/mobile-menu';
import { MobileMenuItem } from '../../../../shared/interfaces/mobile-menu-item';
import { ProductCategory } from '../../../../shared/interfaces/product-category';
import { MobileMenuService } from '../../../../shared/services/mobile-menu.service';
import { ProductService } from '../../../../shared/services/product.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnDestroy, OnInit {
  private destroy$: Subject<any> = new Subject();

  isOpen = false;
  links: MobileMenuItem[] = mobileMenu;
  private categories: ProductCategory[];

  constructor(
    public mobilemenu: MobileMenuService,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    // {type: 'link', label: 'Home 1', url: '/classic'},
    this.mobilemenu.isOpen$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isOpen => (this.isOpen = isOpen));
    if (this.productService.checkCategoriesLoaded()) {
      this.categories = this.productService.getCategories();
      this.updateLinks();
    }
    this.productService.categoriesUpdated.subscribe(() => {
      this.categories = this.productService.getCategories();
      this.updateLinks();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onItemClick(event: MobileMenuItem): void {
    if (event.type === 'link') {
      this.mobilemenu.close();
    }

    // if (event.data && event.data.language) {
    //     console.log(event.data.language); // change language
    // }
  }

  updateLinks() {
    this.links = [];
    this.categories.forEach(cat => {
      this.links.push({
        type: 'link',
        label: cat.name,
        url: 'shop/' + cat.slug,
      });
    });
  }
}
