import { Component } from '@angular/core';
import { navigation } from '../../../../../data/header-navigation';
import { NavigationLink } from '../../../../shared/interfaces/navigation-link';
import { ProductCategory } from '../../../../shared/interfaces/product-category';
import { DirectionService } from '../../../../shared/services/direction.service';
import { ProductService } from '../../../../shared/services/product.service';

@Component({
  selector: 'app-header-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss'],
})
export class LinksComponent {
  items: NavigationLink[] = navigation;
  categories: ProductCategory[] = [];

  constructor(
    private direction: DirectionService,
    private productService: ProductService
  ) {
    if (productService.checkCategoriesLoaded()) {
      this.categories = productService.getCategories().slice(0, 3);
    }

    productService.categoriesUpdated.subscribe(
      categories => (this.categories = categories.slice(0, 3))
    );
  }

  mouseenter(event: MouseEvent): void {
    if (!(event.target instanceof HTMLElement)) {
      return;
    }

    const element: HTMLElement = event.target;
    const megamenu = element.querySelector(
      '.nav-links__megamenu'
    ) as HTMLElement;

    if (!megamenu) {
      return;
    }

    const container = megamenu.offsetParent;
    const containerWidth = container.getBoundingClientRect().width;
    const megamenuWidth = megamenu.getBoundingClientRect().width;

    if (this.direction.isRTL()) {
      const itemPosition =
        containerWidth - (element.offsetLeft + element.offsetWidth);
      const megamenuPosition = Math.round(
        Math.min(itemPosition, containerWidth - megamenuWidth)
      );

      megamenu.style.right = megamenuPosition + 'px';
    } else {
      const itemPosition = element.offsetLeft;
      const megamenuPosition = Math.round(
        Math.min(itemPosition, containerWidth - megamenuWidth)
      );

      megamenu.style.left = megamenuPosition + 'px';
    }
  }
}
