export class ProductCategory {
  public id: number;
  public name: string;
  public slug: string;
  public description: string;
  public count: number;
  public imageURL: string;

  constructor(id, name, slug, description, count, imageURL) {
    this.id = id;
    this.name = name;
    this.slug = slug;
    this.description = description;
    this.count = count;
    this.imageURL = imageURL;
  }
}
