import { Component } from '@angular/core';
import { ProductCategory } from '../../../shared/interfaces/product-category';
import { ProductService } from '../../../shared/services/product.service';

@Component({
  selector: 'app-block-banner',
  templateUrl: './block-banner.component.html',
  styleUrls: ['./block-banner.component.scss'],
})
export class BlockBannerComponent {
  category: ProductCategory;
  constructor(private productService: ProductService) {
    if (productService.checkCategoriesLoaded()) {
      this.category = productService.getCategories()[
        Math.floor(Math.random() * productService.getCategories().length)
      ];
    }
    this.productService.categoriesUpdated.subscribe(
      () =>
        (this.category = productService.getCategories()[
          Math.floor(Math.random() * productService.getCategories().length)
        ])
    );
  }
}
