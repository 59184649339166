import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import detectIt from 'detect-it';
import { RootService } from '../../shared/services/root.service';
import { UserService } from '../../shared/services/user.service';

@Component({
  selector: 'app-main',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnInit {
  headerLayout: string;
  private screenX: number;
  private screenY: number;
  private offSetX = 1975;
  private offSetY = 1975;
  movableOverlay = true;
  showTooltip = true;
  public svg: string;
  tooltipTitle =
    'De tijd loopt! Beweeg met je muis over het scherm en leg jouw gekozen product in de winkelmand.';

  @HostListener('window:resize')
  onResize() {
    this.checkOffset();
  }

  constructor(
    public root: RootService,
    public router: Router,
    public route: ActivatedRoute,
    private userService: UserService
  ) {
    this.route.data.subscribe(
      (data) => (this.headerLayout = data.headerLayout)
    );
    this.root.path = this.router
      .createUrlTree(['./'], { relativeTo: route })
      .toString();

    this.checkOffset();
    setTimeout(() => {
      this.showTooltip = false;
    }, 4000);
  }

  ngOnInit() {
    document.body.classList.remove('intro');
    document.body.classList.add('game');
    this.userService.getProductFound$().subscribe(() => {
      setTimeout(() => {
        this.router.navigate(['result']);
      }, 500);
    });
    // countdown
    const minuteCounter = document.getElementById('minute-counter--time');
    let timeLeft = 59;

    setInterval(() => {
      minuteCounter.innerHTML = timeLeft.toString();
      timeLeft--;
    }, 1000);

    // redirect after 60s
    setTimeout(() => {
      if (this.userService.selectedProduct !== undefined) {
        this.finishGame();
      }
    }, 60000);
  }

  private finishGame() {
    // User did not finish in time
    this.userService
      .foundProduct(new Date().getTime() + 100000)
      .subscribe(() => {});
  }

  private checkOffset(): void {
    if (window.innerWidth < 992 || detectIt.deviceType === 'touchOnly') {
      this.movableOverlay = false;
    } else if (window.innerWidth < 2000) {
      this.movableOverlay = true;
      this.offSetX = 1975; // 4000 * 4000
      this.offSetY = 1975;
    } else if (window.innerWidth < 4000) {
      this.movableOverlay = true;
      this.offSetX = 3975; // 8000 * 8000
      this.offSetY = 3975;
    } else {
      this.movableOverlay = true;
      this.offSetX = 4975; // 10000 * 10000
      this.offSetY = 4975;
    }
  }

  public onMouseMove(e: MouseEvent) {
    this.screenX = e.clientX;
    this.screenY = e.clientY;
  }

  getScreenX(): string {
    if (this.movableOverlay) {
      return this.screenX - this.offSetX + 'px';
    } else {
      return null;
    }
  }

  getScreenY(): string {
    if (this.movableOverlay) {
      return this.screenY - this.offSetY + 'px';
    } else {
      return null;
    }
  }
}
