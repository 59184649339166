import { Component, HostListener } from '@angular/core';
import { posts } from '../../../data/blog-posts';
import { brands } from '../../../data/shop-brands';
import { products } from '../../../data/shop-products';
import { Product2 } from '../../shared/interfaces/product';
import { ProductCategory } from '../../shared/interfaces/product-category';
import { ProductService } from '../../shared/services/product.service';

@Component({
  selector: 'app-home',
  templateUrl: './page-home-one.component.html',
  styleUrls: ['./page-home-one.component.scss'],
})
export class PageHomeOneComponent {
  constructor(private productService: ProductService) {
    if (this.productService.checkProductsLoaded()) {
      this.products = productService.getAllProducts();
      this.featuredProducts.products = this.products.slice(0, 5);
      this.featuredProducts.loading = false;
      this.categories = productService.getCategories();
    } else {
      this.checkDevice();
      productService.productsUpdated.subscribe(prods => {
        this.products = prods;
        this.featuredProducts.products = this.products.slice(0, 5);
        this.categories = productService.getCategories();
        this.featuredProducts.loading = false;
      });
    }
  }
  products: Product2[];
  categories: ProductCategory[];
  posts = posts;
  brands = brands;

  columns = [
    {
      header: 'Top Rated Products',
      products: products.slice(0, 3),
    },
    {
      header: 'Special Offers',
      products: products.slice(3, 6),
    },
    {
      header: 'Bestsellers',
      products: products.slice(6, 9),
    },
  ];

  featuredProducts = {
    loading: true,
    products: [],
    groups: [
      { name: 'All', current: true },
      { name: 'Power Tools', current: false },
      { name: 'Hand Tools', current: false },
      { name: 'Plumbing', current: false },
    ],

    timeout: null, // only for demo

    groupChange: group => {
      // only for demo
      this.featuredProducts.loading = true;

      clearTimeout(this.featuredProducts.timeout);

      this.featuredProducts.timeout = setTimeout(() => {
        const itemsArray = this.products;
        const newItemsArray = [];
        while (itemsArray.length > 0) {
          const randomIndex = Math.floor(Math.random() * itemsArray.length);
          const randomItem = itemsArray.splice(randomIndex, 1)[0];
          newItemsArray.push(randomItem);
        }
        this.featuredProducts.products = newItemsArray;
        this.featuredProducts.loading = false;
      }, 1000);
    },
  };

  newArrivals = {
    loading: false,
    products: products.slice(),
    groups: [
      { name: 'All', current: true },
      { name: 'Power Tools', current: false },
      { name: 'Hand Tools', current: false },
      { name: 'Plumbing', current: false },
    ],

    timeout: null, // only for demo

    groupChange: group => {
      // only for demo
      this.newArrivals.loading = true;

      clearTimeout(this.newArrivals.timeout);

      this.newArrivals.timeout = setTimeout(() => {
        const itemsArray = this.newArrivals.products;
        const newItemsArray = [];
        while (itemsArray.length > 0) {
          const randomIndex = Math.floor(Math.random() * itemsArray.length);
          const randomItem = itemsArray.splice(randomIndex, 1)[0];
          newItemsArray.push(randomItem);
        }
        this.newArrivals.products = newItemsArray;
        this.newArrivals.loading = false;
      }, 1000);
    },
  };

  mobile = false;

  @HostListener('window:resize')
  onResize() {
    this.checkDevice();
  }

  private checkDevice() {
    this.mobile = window.innerWidth < 992;
  }
}
