export interface ProductFeature {
  name: string;
  value: string;
}

export interface ProductFeaturesSection {
  name: string;
  features: ProductFeature[];
}

export interface ProductReview {
  avatar: string;
  author: string;
  rating: number;
  date: string;
  text: string;
}

export interface Product {
  id: number;
  name: string;
  price: number;
  compareAtPrice: number | null;
  images: string[];
  badges: ('sale' | 'new' | 'hot')[];
  rating: number;
  reviews: number;
  availability: string;
  features: ProductFeature[];
  options: Array<any>;
}
import { ProductCategory } from './product-category';
export class Product2 {
  public id: number;
  public name: string;
  public slug: string;
  public price: number;
  public imageURL: string;
  public description: string;
  public shortDescription: string;
  public categoryIds: number[];
  public categories: ProductCategory[];
  public brand: string;
  public reviews: number;
  public rating: number;

  constructor(
    id,
    name,
    slug,
    price,
    imageURL,
    description,
    shortDescription,
    categoryIds,
    brand
  ) {
    this.id = id;
    this.name = name;
    this.slug = slug;
    this.price = price;
    this.imageURL = imageURL;
    this.description = description;
    this.shortDescription = shortDescription;
    this.categoryIds = categoryIds;
    this.categories = [];
    this.brand = brand;
    this.reviews = Math.floor(Math.random() * 100) + 1;
    this.rating = Math.floor(Math.random() * 4) + 2;
  }
}
