import { Component, Input } from '@angular/core';
import { ProductCategory } from '../../../shared/interfaces/product-category';

@Component({
  selector: 'app-block-categories',
  templateUrl: './block-categories.component.html',
  styleUrls: ['./block-categories.component.scss'],
})
export class BlockCategoriesComponent {
  @Input() header = '';
  @Input() layout: 'classic' | 'compact' = 'classic';
  @Input() categories: ProductCategory[] = [];

  constructor() {}
}
