import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Product2 } from '../interfaces/product';
import { ProductCategory } from '../interfaces/product-category';
import {
  ProductFilterCategory,
  ProductFilterCategoryItem,
} from '../interfaces/product-filter';

const API_URL = 'https://bar-campaign.gopublic.work/wp-json/wp/v2';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private products: Product2[];
  private prizes: Product2[];
  private categories: ProductCategory[];
  public productsUpdated = new EventEmitter<Product2[]>();
  public categoriesUpdated = new EventEmitter<ProductCategory[]>();

  constructor(private httpClient: HttpClient) {
    this.products = [];
    this.categories = [];
    this.prizes = [];
    this.updateProductsFromServer();
  }

  public checkProductsLoaded(): boolean {
    return this.products && this.products.length >= 1;
  }

  public checkCategoriesLoaded(): boolean {
    return this.categories && this.categories.length >= 1;
  }

  private updateProductCategories() {
    this.products.forEach(product => {
      product.categoryIds.forEach(id => {
        product.categories.push(this.getCategory(id));
      });
    });

    this.prizes.forEach(product => {
      product.categoryIds.forEach(id => {
        product.categories.push(this.getCategory(id));
      });
    });
    this.productsUpdated.emit(this.products);
  }

  private updateProductsFromServer() {
    this.httpClient
      .get(API_URL + '/products?per_page=99')
      .toPromise()
      .then((res: ProductJSON[]) => {
        res.forEach(product => {
          this.products.push(this.JSONtoProduct(product));
        });
        this.updatePrizesFromServer();
      })
      .catch(err => {
        console.error(err);
      });
  }

  private updatePrizesFromServer() {
    this.httpClient
      .get(API_URL + '/prizes?per_page=99')
      .toPromise()
      .then((res: ProductJSON[]) => {
        res.forEach(product => {
          this.prizes.push(this.JSONtoProduct(product));
        });
        this.updateCategoriesFromServer();
      })
      .catch(err => {
        console.error(err);
      });
  }

  private updateCategoriesFromServer() {
    this.httpClient
      .get(API_URL + '/product_category?per_page=99')
      .toPromise()
      .then((res: CategoryJSON[]) => {
        res.forEach(category => {
          const id = category.id;
          const name = category.name;
          const slug = category.slug;
          const count = category.count;
          const description = category.description;
          const imageURL = category.acf.image;
          this.categories.push(
            new ProductCategory(id, name, slug, description, count, imageURL)
          );
        });
        this.categoriesUpdated.emit(this.categories);
        this.updateProductCategories();
      })
      .catch(err => console.error(err));
  }

  private JSONtoProduct(product: ProductJSON): Product2 {
    const id = product.id;
    const name = product.title.rendered;
    const slug = product.slug;
    const price = +product.acf.price.replace(/,/g, '.');
    const imageURL = product.acf.image;
    const description = product.content.rendered;
    const shortDescription = product.acf.short_description;
    const categoryId = product.product_category;
    const brand = product.acf.brand;

    return new Product2(
      id,
      name,
      slug,
      price,
      imageURL,
      description,
      shortDescription,
      categoryId,
      brand
    );
  }

  private shuffle(array: Product2[]): Product2[] {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  public getProduct(id: number): Product2 {
    if (!this.products || this.products.length < 1) {
      return null;
    }
    return this.products.concat(this.prizes).filter(p => p.id === id)[0];
  }

  public getProductsAndPrizes(): Product2[] {
    return this.shuffle(this.products.concat(this.prizes));
  }

  public getCategories(): ProductCategory[] {
    return this.categories;
  }

  public getCategory(id: number): ProductCategory {
    if (!this.categories || this.categories.length < 1) {
      return null;
    }
    return this.categories.filter(c => c.id === id)[0];
  }

  public getAllProducts(): Product2[] {
    return this.products;
  }

  public getAllPrizes(): Product2[] {
    return this.prizes;
  }

  public getAllProductsForCategorySlug(slug: string): Product2[] {
    const category = this.categories.find(cat => cat.slug === slug);
    const result = [];

    this.products.forEach(prod => {
      prod.categoryIds.forEach(cat => {
        if (cat === category.id) {
          result.push(prod);
          return;
        }
      });
    });

    return result;
  }

  public getAllProductsAndPrizesForCategorySlug(slug: string): Product2[] {
    const category = this.categories.find(cat => cat.slug === slug);
    const result = [];

    this.shuffle(this.products.concat(this.prizes)).forEach(prod => {
      prod.categoryIds.forEach(cat => {
        if (cat === category.id) {
          result.push(prod);
          return;
        }
      });
    });

    return result;
  }

  public getCategoriesForFilter(): ProductFilterCategory {
    const items: ProductFilterCategoryItem[] = [];
    this.categories.forEach(cat => {
      const item: ProductFilterCategoryItem = {
        type: 'current',
        count: cat.count,
        name: cat.name,
        slug: cat.slug,
      };
      items.push(item);
    });

    return {
      name: 'Categories',
      type: 'categories',
      options: { items },
    };
  }

  public getHighestProductPrice(): number {
    return this.products.concat(this.prizes).sort((a, b) => {
      if (a.price > b.price) {
        return -1;
      }
      if (a.price < b.price) {
        return 1;
      }
      return 0;
    })[0].price;
  }

  public getLowestProductPrice(): number {
    return this.products.concat(this.prizes).sort((a, b) => {
      if (a.price > b.price) {
        return 1;
      }
      if (a.price < b.price) {
        return -1;
      }
      return 0;
    })[0].price;
  }
}

interface ProductJSON {
  id: number;
  title: { rendered: string };
  slug: string;
  acf: {
    price: string;
    image: string;
    short_description: string;
    brand: string;
  };
  content: { rendered: string };
  product_category: number[];
}

interface CategoryJSON {
  id: number;
  name: string;
  slug: string;
  count: number;
  description: string;
  acf: { image: string };
}
